<template>
  <div class="custom-line-item-template line-item-v2">
    <ul
      style="
        list-style: none;
        background-color: #f3f6f9;
        font-weight: 700;
        font-size: 16px;
      "
      class="mb-0 custom-grey-border"
    >
      <li class="ltx-header py-3">
        <div class="d-flex w-100 indigo--text text--darken-4">
          <div style="width: 59%">Package ({{ ltxPackage.length }})</div>
          <div style="width: 12%" class="text-center">Quantity</div>
          <div style="width: 12%" class="text-center">Rate</div>
          <div style="width: 12%" class="text-center">Total</div>
          <div style="width: 5%" class="text-center"></div>
        </div>
      </li>
    </ul>
    <ul style="list-style: none" class="pl-0 mb-0 custom-grey-border">
      <template v-if="ltxPackage.length">
        <li
          class="ltx-header py-3 line-item-drag-ltx"
          v-for="(row, index) in ltxPackage"
          :key="`line-item-package-${index}`"
          :class="{ 'custom-border-bottom': ltxPackage[index + 1] }"
        >
          <div class="d-flex w-100">
            <div style="width: 59%" class="d-flex">
              <div class="px-2">
                <v-avatar size="100">
                  <v-img contain :src="row.product_image"></v-img>
                </v-avatar>
              </div>
              <div>
                <v-text-field
                  dense
                  filled
                  hide-details
                  color="cyan"
                  solo
                  flat
                  readonly
                  placeholder="Package"
                  v-model="row.product"
                >
                  <template v-slot:prepend-inner>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          small
                          label
                          class="mr-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="orange"
                        >
                          {{ row.barcode }}
                        </v-chip>
                      </template>
                      <span class="text-capitalize">PACKAGE #</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:append>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          small
                          label
                          class="mr-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="orange"
                        >
                          PACKAGE
                        </v-chip>
                      </template>
                      <span class="text-capitalize">Type</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
                <tiny-mce v-model="row.description" />
              </div>
            </div>
            <div style="width: 41%">
              <div class="w-100 d-flex">
                <div style="width: 29.27%">
                  <v-layout class="line-item-uom">
                    <v-flex style="width: 60px">
                      <v-text-field
                        dense
                        filled
                        hide-details
                        color="cyan"
                        solo
                        flat
                        type="number"
                        v-on:keypress="is_number($event)"
                        placeholder="Quantity"
                        v-model="row.quantity"
                      />
                    </v-flex>
                    <v-flex style="width: 100px">
                      <v-select
                        :items="ltxUomList"
                        dense
                        filled
                        flat
                        hide-details
                        solo
                        v-model="row.uom"
                        placeholder="UOM"
                        label="UOM"
                        color="cyan"
                        item-color="cyan"
                      />
                    </v-flex>
                  </v-layout>
                </div>
                <div style="width: 29.27%">
                  <v-text-field
                    dense
                    filled
                    hide-details
                    color="cyan"
                    solo
                    flat
                    prefix="$"
                    type="number"
                    v-on:keypress="is_number($event)"
                    placeholder="Rate"
                    v-model="row.rate"
                  />
                  <p
                    class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500"
                  >
                    Supplier Cost: <br />
                    <template v-if="row.edit_supplier_cost">
                      <v-text-field
                        dense
                        :id="`supplier-price-${index}-package`"
                        :ref="`supplier-price-${index}-package`"
                        filled
                        hide-details
                        color="cyan"
                        solo
                        flat
                        prefix="$"
                        type="number"
                        v-on:keypress="is_number($event)"
                        placeholder="Supplier Cost"
                        v-model="row.company_cost"
                        v-on:change="row.edit_supplier_cost = false"
                        v-on:blur="row.edit_supplier_cost = false"
                      />
                    </template>
                    <template v-else>
                      <div class="d-flex align-center">
                        <label class="m-0 p-0 font-weight-600">{{
                          formatMoney(row.company_cost)
                        }}</label>
                        <v-btn
                          v-on:click="
                            row.edit_supplier_cost = true;
                            editSupplier(index);
                          "
                          small
                          icon
                          color="cyan"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </p>
                  <p
                    class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500"
                  >
                    Profit:
                    <label class="m-0 p-0 font-weight-600">{{
                      formatMoney(row.profit)
                    }}</label>
                  </p>
                  <p
                    class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500"
                  >
                    Markup (in %):
                    <label class="m-0 p-0 font-weight-600">
                      {{ formatNumber(row.markup_percent) }} %</label
                    >
                  </p>
                </div>
                <div style="width: 41.46%">
                  <v-text-field
                    dense
                    filled
                    hide-details
                    color="cyan"
                    solo
                    flat
                    readonly
                    class="has-delete-outer-icon"
                    append-outer-icon="mdi-delete"
                    v-on:click:append-outer="deletePackage(index)"
                    placeholder="Total"
                    :value="formatMoney(row.total)"
                  />
                  <p
                    class="w-100 mt-2 mb-0 text-left font-size-16 font-weight-500"
                  >
                    Total Supplier Cost:<br /><label
                      class="m-0 p-0 font-weight-600"
                    >
                      {{ formatMoney(row.total_company_cost) }}
                    </label>
                  </p>
                  <p
                    class="w-100 mt-11 mb-0 text-left font-size-16 font-weight-500"
                  >
                    Profit (in %):
                    <label class="m-0 p-0 font-weight-600"
                      >{{ formatNumber(row.profit_percent) }} %</label
                    >
                  </p>
                </div>
              </div>
              <div class="px-2 mt-4 custom-border-top">
                <label class="font-weight-700 font-size-16 py-3"
                  >Internal Note</label
                >
                <tiny-mce v-model="row.internal_note" />
              </div>
            </div>
          </div>
        </li>
      </template>
    </ul>
    <template v-if="!ltxPackage.length">
      <div
        style="font-weight: 700; font-size: 16px"
        class="custom-grey-border text-center py-2 red--text text--darken-1"
      >
        No Package Found
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { DELETE_PACKAGE } from "@/core/services/store/line.item.module";

export default {
  mixins: [CommonMixin],
  name: "line-item-package",
  props: {
    itemInstance: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    "tiny-mce": TinyMCE,
  },
  methods: {
    editSupplier(index) {
      setTimeout(() => {
        if (
          this.itemInstance.hasProperty(this.$refs, [
            `supplier-price-${index}-package`,
            0,
            "$refs",
            "input",
          ])
        ) {
          this.$refs[`supplier-price-${index}-package`][0].$refs.input.focus();
        }
      }, 100);
    },
    deletePackage(index) {
      this.$store.dispatch(DELETE_PACKAGE, index);
    },
  },
  computed: {
    ...mapGetters(["ltxPackage", "ltxUomList"]),
  },
};
</script>

import store from "@/core/services/store";
import {
  SET_PACKAGE,
  SET_CHILD_ITEM,
  SET_LEVEL2_CHILD_ITEM,
  SET_CALCULATION,
} from "@/core/services/store/line.item.module";
import {
  safeAdd,
  safeSubtract,
  safeMultiply,
  safeDivide,
  toSafeNumber,
} from "@/core/plugins/math.lib";
import { isEqual, cloneDeep } from "lodash";
import ObjectPath from "object-path";

class ManageLineItem {
  packageTimeout = null;
  levelTwoTimeout = null;
  levelThreeTimeout = null;
  calculationTimeout = null;
  packageTimeoutLimit = 500;
  levelTwoTimeoutLimit = 600;
  levelThreeTimeoutLimit = 700;
  calculationTimeoutLimit = 1000;

  packageOldValue = null;
  levelTwoOldValue = null;
  levelThreeOldValue = null;
  calculationOldValue = null;

  packageTotal = 0;
  packageSupplierTotal = 0;
  levelTwoTotal = 0;
  levelTwoSupplierTotal = 0;
  levelTwoOptionalTotal = 0;
  levelThreeTotal = 0;
  levelThreeSupplierTotal = 0;
  levelThreeOptionalTotal = 0;

  constructor() {}

  init() {
    store.watch(
      (state) => state.lineitem.packages,
      (newValue) => {
        clearTimeout(this.packageTimeout);
        this.packageTimeout = setTimeout(() => {
          this.packageCalculation(newValue);
        }, this.packageTimeoutLimit);
      },
      { deep: true }
    );

    store.watch(
      (state) => state.lineitem.child_items,
      (newValue) => {
        clearTimeout(this.levelTwoTimeout);
        this.levelTwoTimeout = setTimeout(() => {
          this.levelTwoCalculation(newValue);
        }, this.levelTwoTimeoutLimit);
      },
      { deep: true }
    );

    store.watch(
      (state) => state.lineitem.level2_child_items,
      (newValue) => {
        clearTimeout(this.levelThreeTimeout);
        this.levelThreeTimeout = setTimeout(() => {
          this.levelThreeCalculation(newValue);
        }, this.levelThreeTimeoutLimit);
      },
      { deep: true }
    );

    store.watch(
      (state) => state.lineitem.calculation,
      (newValue) => {
        clearTimeout(this.calculationTimeout);
        this.calculationTimeout = setTimeout(() => {
          const newValueCloneDeep = cloneDeep(newValue);
          this.performCalculation(false, newValueCloneDeep);
        }, this.calculationTimeoutLimit);
      },
      { deep: true }
    );
  }

  hasProperty(obj, key) {
    return ObjectPath.has(obj, key);
  }

  getObject(obj, key) {
    ObjectPath.get(obj, key);
  }

  setObject(obj, key, val) {
    ObjectPath.set(obj, key, val);
  }

  performCalculation(force, newValue) {
    const oldValue = this.calculationOldValue;

    if (!isEqual(newValue, oldValue) || force) {
      const calculation = cloneDeep(store?.getters?.ltxCalculation);

      this.calculationOldValue = calculation;

      calculation.sub_total = safeAdd(this.levelTwoTotal, this.levelThreeTotal, this.packageTotal);
      calculation.optional_total = safeAdd(this.levelTwoOptionalTotal, this.levelThreeOptionalTotal);

      calculation.sub_total = toSafeNumber(calculation.sub_total);
      calculation.optional_total = toSafeNumber(calculation.optional_total);
      calculation.discount_value = toSafeNumber(calculation.discount_value);
      calculation.discount_type = toSafeNumber(calculation.discount_type);
      calculation.discount_amount = toSafeNumber(calculation.discount_amount);
      calculation.discount_two_value = toSafeNumber(calculation.discount_two_value);
      calculation.discount_two_type = toSafeNumber(calculation.discount_two_type);
      calculation.discount_two_amount = toSafeNumber(calculation.discount_two_amount);
      calculation.tax_applied = toSafeNumber(calculation.tax_applied);
      calculation.tax_value = toSafeNumber(calculation.tax_value);
      calculation.taxable_amount = toSafeNumber(calculation.taxable_amount);
      calculation.tax_amount = toSafeNumber(calculation.tax_amount);
      calculation.adjustment = toSafeNumber(calculation.adjustment);
      calculation.total = toSafeNumber(calculation.total);
      calculation.acc_disc_amnt = toSafeNumber(calculation.acc_disc_amnt);

      calculation.acc_supplier = 0;
      calculation.acc_profit = 0;
      calculation.acc_markup_percent = 0;
      calculation.acc_profit_percent = 0;

      if (calculation.sub_total > 0) {

        if (calculation.discount_type == 1) {
          if (calculation.discount_value > calculation.sub_total) {
            calculation.discount_value = calculation.sub_total;
          }

          calculation.discount_amount = calculation.discount_value;
        } else if (calculation.discount_type == 2) {
          if (calculation.discount_value > 100) {
            calculation.discount_value = 100;
          }

          calculation.discount_amount = safeMultiply(safeDivide(calculation.sub_total, 100), calculation.discount_value);
        }

        const after_first_discount = safeSubtract(calculation.sub_total, calculation.discount_amount);

        if (calculation.discount_two_type == 1) {
          if (calculation.discount_two_value > after_first_discount) {
            calculation.discount_two_value = after_first_discount;
          }

          calculation.discount_two_amount = calculation.discount_two_value;
        } else if (calculation.discount_two_type == 2) {
          if (calculation.discount_two_value > 100) {
            calculation.discount_two_value = 100;
          }

          calculation.discount_two_amount = safeMultiply(safeDivide(after_first_discount, 100), calculation.discount_two_value);
        }

      }

      calculation.acc_disc_amnt = safeAdd(calculation.discount_amount, calculation.discount_two_amount);

      calculation.taxable_amount = safeSubtract(calculation.sub_total, calculation.acc_disc_amnt);
      calculation.tax_amount = 0;

      if (calculation.tax_applied == 1) {
        calculation.tax_amount = safeMultiply(safeDivide(calculation.taxable_amount, 100), calculation.tax_value);
      }

      calculation.acc_supplier = safeAdd(this.packageSupplierTotal, this.levelTwoSupplierTotal, this.levelThreeSupplierTotal);

      if (calculation.sub_total > 0 && calculation.acc_supplier > 0) {
        calculation.acc_profit = safeSubtract(calculation.sub_total, safeAdd(calculation.acc_supplier, calculation.acc_disc_amnt));
        calculation.acc_markup_percent = safeMultiply(safeSubtract(safeDivide(safeSubtract(calculation.sub_total, calculation.acc_disc_amnt), calculation.acc_supplier), 1), 100);
        calculation.acc_profit_percent = safeMultiply(safeDivide(calculation.acc_profit, calculation.sub_total), 100);
      }

      calculation.total = safeAdd(calculation.adjustment, calculation.taxable_amount, calculation.tax_amount);

      calculation.sub_total = toSafeNumber(calculation.sub_total);
      calculation.optional_total = toSafeNumber(calculation.optional_total);
      calculation.discount_value = toSafeNumber(calculation.discount_value);
      calculation.discount_type = toSafeNumber(calculation.discount_type);
      calculation.discount_amount = toSafeNumber(calculation.discount_amount);
      calculation.discount_two_value = toSafeNumber(calculation.discount_two_value);
      calculation.discount_two_type = toSafeNumber(calculation.discount_two_type);
      calculation.discount_two_amount = toSafeNumber(calculation.discount_two_amount);
      calculation.acc_disc_amnt = toSafeNumber(calculation.acc_disc_amnt);
      calculation.tax_applied = toSafeNumber(calculation.tax_applied);
      calculation.tax_value = toSafeNumber(calculation.tax_value);
      calculation.taxable_amount = toSafeNumber(calculation.taxable_amount);
      calculation.tax_amount = toSafeNumber(calculation.tax_amount);
      calculation.adjustment = toSafeNumber(calculation.adjustment);
      calculation.total = toSafeNumber(calculation.total);
      calculation.acc_supplier = toSafeNumber(calculation.acc_supplier);
      calculation.acc_profit = toSafeNumber(calculation.acc_profit);
      calculation.acc_markup_percent = toSafeNumber(calculation.acc_markup_percent);
      calculation.acc_profit_percent = toSafeNumber(calculation.acc_profit_percent);

      store.commit(SET_CALCULATION, calculation);
    }
  }

  packageCalculation(newValue) {
    const oldValue = this.packageOldValue;
    if (!isEqual(newValue, oldValue)) {
      const request = cloneDeep(store?.getters?.ltxPackage);
      this.packageOldValue = request;
      this.packageTotal = 0;
      this.packageSupplierTotal = 0;

      for (let i = 0; i < request.length; i++) {
        request[i].total = safeMultiply(request[i].rate, request[i].quantity);
        request[i].total_company_cost = safeMultiply(request[i].company_cost, request[i].quantity);
        request[i].profit = this.profitCalculate(request[i]);
        request[i].profit_percent = this.profitPercentCalculate(request[i]);
        request[i].markup_percent = this.markupPercentCalculate(request[i]);
        this.packageTotal = safeAdd(this.packageTotal, request[i].total);
        this.packageSupplierTotal = safeAdd(this.packageSupplierTotal, request[i].total_company_cost);
      }

      clearTimeout(this.calculationTimeout);
      this.calculationTimeout = setTimeout(() => {
        const ltxCalculation = cloneDeep(store?.getters?.ltxCalculation);
        this.performCalculation(true, ltxCalculation);
      }, this.calculationTimeoutLimit);

      store.commit(SET_PACKAGE, request);
    }
  }

  levelTwoCalculation(newValue) {
    const oldValue = this.levelTwoOldValue;
    if (!isEqual(newValue, oldValue)) {
      const request = cloneDeep(store?.getters?.ltxChildLineItem);
      this.levelTwoOldValue = request;
      this.levelTwoOptionalTotal = 0;
      this.levelTwoSupplierTotal = 0;
      this.levelTwoTotal = 0;

      for (const key in request) {
        if (this.hasProperty(request, key) && Array.isArray(request[key])) {
          for (let i = 0; i < request[key].length; i++) {
            request[key][i].total = safeMultiply(request[key][i].rate, request[key][i].quantity);
            request[key][i].total_company_cost = safeMultiply(request[key][i].company_cost, request[key][i].quantity);
            request[key][i].profit = this.profitCalculate(request[key][i]);
            request[key][i].profit_percent = this.profitPercentCalculate(request[key][i]);
            request[key][i].markup_percent = this.markupPercentCalculate(request[key][i]);

            if (Number(request[key][i].is_optional) === 1) {
              this.levelTwoOptionalTotal = safeAdd(this.levelTwoOptionalTotal, request[key][i].total);
            } else if (Number(request[key][i].strike) === 0) {
              this.levelTwoTotal = safeAdd(this.levelTwoTotal, request[key][i].total);
              this.levelTwoSupplierTotal = safeAdd(this.levelTwoSupplierTotal, request[key][i].total_company_cost);
            }
          }
        }
      }      

      clearTimeout(this.calculationTimeout);
      this.calculationTimeout = setTimeout(() => {
        const ltxCalculation = cloneDeep(store?.getters?.ltxCalculation);
        this.performCalculation(true, ltxCalculation);
      }, this.calculationTimeoutLimit);

      store.commit(SET_CHILD_ITEM, request);
    }
  }

  levelThreeCalculation(newValue) {
    const oldValue = this.levelThreeOldValue;
    if (!isEqual(newValue, oldValue)) {
      const request = cloneDeep(store?.getters?.ltxLevel2ChildLineItem);
      this.levelThreeOldValue = request;
      this.levelThreeOptionalTotal = 0;
      this.levelThreeSupplierTotal = 0;
      this.levelThreeTotal = 0;

      for (const key in request) {
        if (this.hasProperty(request, key)) {
          for (const lkey in request[key]) {
            if (this.hasProperty(request, [key, lkey]) && Array.isArray(request[key][lkey])) {
              for (let i = 0; i < request[key][lkey].length; i++) {
                request[key][lkey][i].total = safeMultiply(request[key][lkey][i].rate, request[key][lkey][i].quantity);
                request[key][lkey][i].total_company_cost = safeMultiply(request[key][lkey][i].company_cost, request[key][lkey][i].quantity);
                request[key][lkey][i].profit = this.profitCalculate(request[key][lkey][i]);
                request[key][lkey][i].profit_percent = this.profitPercentCalculate(request[key][lkey][i]);
                request[key][lkey][i].markup_percent = this.markupPercentCalculate(request[key][lkey][i]);

                if (Number(request[key][lkey][i].is_optional) === 1) {
                  this.levelThreeOptionalTotal = safeAdd(this.levelThreeOptionalTotal, request[key][lkey][i].total);
                } else if (Number(request[key][lkey][i].strike) === 0) {
                  this.levelThreeTotal = safeAdd(this.levelThreeTotal, request[key][lkey][i].total);
                  this.levelThreeSupplierTotal = safeAdd(this.levelThreeSupplierTotal, request[key][lkey][i].total_company_cost);
                }
              }
            }
          }
        }
      }

      clearTimeout(this.calculationTimeout);
      this.calculationTimeout = setTimeout(() => {
        const ltxCalculation = cloneDeep(store?.getters?.ltxCalculation);
        this.performCalculation(true, ltxCalculation);
      }, this.calculationTimeoutLimit);

      store.commit(SET_LEVEL2_CHILD_ITEM, request);
    }
  }

  profitCalculate({ company_cost, quantity, rate }) {
    let total_cost = safeMultiply(quantity, rate);
    let supplier_cost = safeMultiply(company_cost, quantity);
    return safeSubtract(total_cost, supplier_cost);
  }

  profitPercentCalculate({ company_cost, quantity, rate }) {
    let total_cost = safeMultiply(quantity, rate);
    let supplier_cost = safeMultiply(company_cost, quantity);
    let profit = safeSubtract(total_cost, supplier_cost);
    return safeMultiply(safeDivide(profit, total_cost), 100);
  }

  markupPercentCalculate({ company_cost, quantity, rate }) {
    let total_cost = safeMultiply(quantity, rate);
    let supplier_cost = safeMultiply(company_cost, quantity);
    if (supplier_cost > 0 && total_cost > 0) {
      return safeMultiply(safeSubtract(safeDivide(total_cost, supplier_cost), 1), 100);
    }
    return 0;
  }
}

export default ManageLineItem;

<template>
  <div>
    <div class="d-flex w-100" :class="{ 'custom-border-bottom': hasLevelTwo }">
      <div style="width: 5%" class="pt-5 text-center">
        <v-icon class="line-item-drag-icon cursor-move color-custom-blue"
          >mdi-drag</v-icon
        >
        <br />
        <label class="px-0 mx-0 py-0 mt-2 my-0font-weight-600 font-size-18">
          VO <span v-if="pagebreak">{{ index }}.</span
          ><span v-else>{{ index + 1 }}.</span>
        </label>
      </div>
      <div style="width: 54%">
        <v-autocomplete
          dense
          filled
          color="cyan"
          item-color="cyan"
          :items="ltxAdTitleList"
          placeholder="Additional Work Title"
          solo
          flat
          :readonly="hasLevelTwo"
          hide-details
          item-text="name"
          item-value="id"
          class="my-2 width-100"
          v-model.trim="row.product_id"
          v-on:change="
            updateAdtitle($event, index);
            levelFilter($event);
            emitValue();
          "
        />
        <v-textarea
          auto-grow
          dense
          filled
          color="cyan"
          placeholder="Description"
          solo
          flat
          class="my-2"
          hide-details
          row-height="10"
          v-model="row.description"
          v-on:keyup="emitValue()"
          v-on:change="emitValue()"
        />
        <div class="d-flex">
          <div style="position:relative" v-for="(nimg, nmlindex) in imageSrc" :key="`new-image-${nmlindex}`">
            <v-img :aspect-ratio="1" height="100px" width="100px" class="mr-4 my-4" :src="nimg">              
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey darken-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-btn style="position: absolute; top: 0; right: 0" icon color="red darken-1" v-on:click="removeImage(nmlindex)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-layout class="align-center">
          <v-flex md6 class="text-left">
            <div class="d-flex align-center">
              <v-btn
                :disabled="!row.product_id"
                v-on:click="addChildItem(row.uuid, row.product_id)"
                small
                depressed
                color="cyan white--text"
                class="mt-2 custom-bold-button white--text"
              >
                <v-icon>mdi-plus</v-icon> Add Additional Work (Same Category)
              </v-btn>
              <v-btn
                :disabled="!row.product_id"
                v-on:click="selectImage()"
                small
                depressed
                color="cyan white--text"
                class="mt-2 ml-4 custom-bold-button white--text"
              >
                <v-icon>mdi-cloud-upload-outline</v-icon> Upload Image
              </v-btn>
            </div>
            <br />
            <v-btn
              :disabled="!row.product_id"
              v-on:click="
                row.pagebreak = !row.pagebreak;
                emitValue();
              "
              small
              depressed
              color="cyan white--text"
              class="mb-4 custom-bold-button white--text"
            >
              <span v-if="row.pagebreak"
                ><v-icon>mdi-minus</v-icon> Remove Page Break</span
              >
              <span v-else><v-icon>mdi-plus</v-icon> Add Page Break</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
      <div style="width: 41%" class="text-right">
        <v-btn
          v-on:click="
            deleteAdtitle(index);
            emitValue();
          "
          color="red lighten-1"
          icon
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>


    <!-- Upload Image :: begin -->
    <div class="d-none">
      <v-file-input
        :ref="`line-item-image-${index}`"
        v-on:change="updateProfileImage"
        multiple
        accept="image/png, image/jpeg, image/jpg"
      ></v-file-input>
    </div>
    <!-- Upload Image :: end -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  EMIT_VALUE,
  UPDATE_ADTITLE,
  UPDATE_IMAGE,
  LEVEL_FILTER,
  ADD_CHILD_ITEM,
  DELETE_ADTITLE,
} from "@/core/services/store/line.item.module";

export default {
  data() {
    return {      
      imageRules: [
        (value) => !value || value.size < 2000000 || "Image size should be less than 2 MB!",
      ],
      imageSrc: [],
    };
  },
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    pagebreak: {
      type: Boolean,
      default: false,
    },
    hasLevelTwo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectImage() {
      this.$refs[`line-item-image-${this.index}`].$refs["input"].click();
    },
    updateProfileImage(param) {
      if (param && param.length) {
        const promises = [];
        for (let i = 0; i < param.length; i++) {
          promises.push(this.convertImageToBase64(param[i]));
        }

        Promise.all(promises).then(() => {
            this.$store.dispatch(UPDATE_IMAGE, { index: this.index, images: this.imageSrc });
          })
          .catch((error) => {
            console.error('Error loading images:', error);
          })
          .finally(() => {
            this.$refs[`line-item-image-${this.index}`].$refs["input"].value = null;
          });
      }
    },
    convertImageToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageSrc.push(reader.result);
          resolve();
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    removeImage(index) {
      this.imageSrc.splice(index, 1);
      this.$store.dispatch(UPDATE_IMAGE, { index: this.index, images: this.imageSrc });
    },
    updateAdtitle(productId, index) {
      this.$store.dispatch(UPDATE_ADTITLE, { productId, index });
    },
    levelFilter(productId) {
      this.$store.dispatch(LEVEL_FILTER, { productId });
    },
    emitValue() {
      this.$store.dispatch(EMIT_VALUE);
    },
    addChildItem(uuid, productId) {
      this.$store.dispatch(ADD_CHILD_ITEM, { uuid, productId, indexToInsert: -1 });
    },
    deleteAdtitle(index) {
      this.$store.dispatch(DELETE_ADTITLE, index);
    },
  },
  computed: {
    ...mapGetters(["ltxAdTitleList"]),
    allImagesLoaded() {
      return this.loadedImages.length === this.imageSrc.length;
    },
  },
};
</script>
